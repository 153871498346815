import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; // Стандартная сборка CKEditor 5

import "./Editor.css"; // Импорт стилей

export default function Editor({ handleData, initData = "" }) {
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
        items: [
            'undo', 'redo',
            '|',
            'heading',
            '|',
            'bold', 'italic', 'strikethrough',   // без font families и sizes
            '|',
            'link',
            'uploadImage', 
            'blockQuote',
            '|',
            'bulletedList', 'numberedList'
        ],
        shouldNotGroupWhenFull: true,
    },
    // ckfinder будет работать, если он поддерживается стандартной сборкой
    ckfinder: {
      uploadUrl: `${process.env.REACT_APP_API_URL}/api/CKEditor/CKEditorUpload`,
      options: {
        resourceType: "Images",
      },
    },
    initialData: initData,
    placeholder: "Введите или вставьте свой контент здесь!",
  };

  return (
    <div className="editor-container__editor">
      <div ref={editorRef}>
        {isLayoutReady && (
          <CKEditor
            editor={ClassicEditor} // Используйте стандартный редактор
            config={editorConfig} // Передаем конфигурацию
            onBlur={(_event, editor) => {
              handleData(editor.getData()); // Обновление данных при изменении
            }}
            data={initData} // Начальные данные для редактора
          />
        )}
      </div>
    </div>
  );
}
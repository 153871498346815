import { useState, useEffect } from 'react';

const useSavePagePagination = (key, initialPage = 0, initialLimit = 10) => {
    const savedState = JSON.parse(sessionStorage.getItem(key)) || { page: initialPage, limit: initialLimit };

    const [page, setPage] = useState(savedState.page);
    const [limit, setLimit] = useState(savedState.limit);


    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify({ page, limit }));
    }, [page, limit, key]);

    return { page, limit, setPage, setLimit };
};

export default useSavePagePagination;
